import addYears from 'date-fns/addYears';
import Cookies from 'js-cookie';
import { createIntl, createIntlCache, MessageDescriptor } from 'react-intl';

import deMessages from '~/config/intl/messages/de.json';
import enMessages from '~/config/intl/messages/en.json';
import esMessages from '~/config/intl/messages/es.json';
import frMessages from '~/config/intl/messages/fr.json';
import jaMessages from '~/config/intl/messages/ja.json';
import koMessages from '~/config/intl/messages/ko.json';
import ptMessages from '~/config/intl/messages/pt.json';
import { DEFAULT_LOCALE, DETECTED_LOCALE, LOCALE } from '~/constants';
import { SupportedLocale } from '~/types';

import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';

import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/ja';
import '@formatjs/intl-pluralrules/locale-data/ko';
import '@formatjs/intl-pluralrules/locale-data/pt';

import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/ko';
import '@formatjs/intl-relativetimeformat/locale-data/pt';

import '@formatjs/intl-displaynames/locale-data/de';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/es';
import '@formatjs/intl-displaynames/locale-data/fr';
import '@formatjs/intl-displaynames/locale-data/ja';
import '@formatjs/intl-displaynames/locale-data/ko';
import '@formatjs/intl-displaynames/locale-data/pt';

type Messages = {
  [key in SupportedLocale]: Record<string, string>;
};

const messages: Messages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  ko: koMessages,
  pt: ptMessages,
};

const supportedLocales = Object.keys(messages) as Array<SupportedLocale>;

export const getLocale = () => {
  let locale;
  const cookiesLocale = Cookies.get(LOCALE) || Cookies.get(DETECTED_LOCALE);
  const pathName = window.location.pathname;
  const navLanguage =
    (navigator.languages && navigator.languages[0]) || navigator.language;

  if (cookiesLocale) {
    locale = cookiesLocale;
  } else if (pathName.includes('checkout') && navLanguage.startsWith('es')) {
    // This condition preserves the behavior in production today, where users with Spanish
    // browser settings will have the checkout page rendered with Spanish text.
    locale = navLanguage;
  } else {
    locale = DEFAULT_LOCALE;
  }

  return locale;
};

export const setLocale = (locale: SupportedLocale) => {
  Cookies.set(LOCALE, locale, { expires: addYears(new Date(), 20) });
};

export const getMessagesForLocale = (locale: string) => {
  const localeWithoutRegionCode = locale.toLowerCase().slice(0, 2);
  const supportedLocale =
    supportedLocales.find((value) => value === localeWithoutRegionCode) ??
    DEFAULT_LOCALE;

  return messages[supportedLocale];
};

export const getLanguageDisplayName = (locale: SupportedLocale) => {
  return new Intl.DisplayNames([locale], { type: 'language' }).of(locale);
};

const locale = getLocale();
const cache = createIntlCache();

export const intl = createIntl(
  {
    locale,
    messages: getMessagesForLocale(locale),
  },
  cache,
);

export const formatMessage = (
  descriptor: MessageDescriptor,
  values?: Record<string, string | number | boolean | Date | null | undefined>,
): string => intl.formatMessage(descriptor, values);
